import React from "react";
import { Box, Container, Typography, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { useInView } from "react-intersection-observer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const NumberBox = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  textAlign: "center",
  position: "relative",
  overflow: "hidden",
  cursor: "default",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const numbersData = [
  {
    title: 70,
    suffix: "%",
    description:
      "de Chilenos no saben con certeza a quién acudir según sus síntomas.",
  },
  {
    title: 62,
    suffix: "%",
    description:
      "de Latinoamericanos postergaron atención médica en el último año.",
  },
  {
    title: 14,
    suffix: "%",
    description:
      "de consumidores en LATAM no acudieron a atención médica por no saber dónde ir.",
  },
  {
    title: 31,
    suffix: "%",
    description:
      "de los consumidores en LATAM postergaron atención por dificultades de acceso.",
  },
  {
    title: 900,
    suffix: "%",
    description:
      "de crecimiento en la salud digital en Chile durante la pandemia.",
  },
  {
    title: 57.2,
    prefix: "$",
    suffix: "B",
    description:
      "es el tamaño proyectado del mercado global de salud impulsado por IA para 2032.",
  },
];

export default function NumbersSection() {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Lowered threshold for mobile
    onChange: (inView) => {
    }
  });

  const numberRefs = React.useRef([]);
  numberRefs.current = [];

  React.useEffect(() => {
    if (inView) {
      const tl = gsap.timeline();
      const isMobile = window.innerWidth < 600; // Mobile check

      tl.fromTo(
        ".numbers-title",
        {
          opacity: 0,
          scaleX: isMobile ? 1 : 1.2,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          scaleX: 1,
          duration: 0.8,
          ease: "power3.out",
        }
      );

      tl.fromTo(
        ".number-box",
        { 
          y: 50, 
          opacity: 0, 
          scaleX: isMobile ? 1 : 1.2 
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
          stagger: isMobile ? 0.1 : 0.2,
          scaleX: 1,
          ease: "power3.out",
          onStart: () => {
            numberRefs.current.forEach((ref, index) => {
              const { title, prefix = "", suffix = "" } = numbersData[index];
              let start = 0;
              const end = title;
              const duration = 1500;
              const increment = end / (duration / 16.67);
              const isFloat = title % 1 !== 0;

              function animateNumber() {
                start += increment;
                if (start >= end) {
                  start = end;
                }
                const value = isFloat ? start.toFixed(1) : Math.floor(start);
                ref.innerText = `${prefix}${value}${suffix}`;
                if (start < end) {
                  requestAnimationFrame(animateNumber);
                }
              }

              animateNumber();
            });
          },
        },
        "-=0.6"
      );
    }
  }, [inView]);

  return (
    <Box
      sx={{
        width: "100%",
      }}
      ref={ref}
    >
      <StyledContainer>
        <Typography
          variant="h3"
          component="h2"
          align="center"
          gutterBottom
          className="numbers-title"
          sx={{ fontWeight: 700 }}
        >
          Números que <b className="animated-gradient-text">importan.</b>
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          paragraph
          sx={{ marginBottom: theme.spacing(6) }}
          className="numbers-title"
        >
          En Chile y LATAM, Vita promete hacer un cambio.
        </Typography>
        <Grid container spacing={isSmUp ? 4 : 2}>
          {numbersData.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{ display: "flex", opacity: 0 }}
              className="number-box"
            >
              <NumberBox>
                <Typography
                  variant="h2"
                  component="div"
                  sx={{ fontWeight: 900 }}
                  ref={(el) => (numberRefs.current[index] = el)}
                >
                  {item.prefix || ""}0{item.suffix || ""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginTop: theme.spacing(2) }}
                >
                  {item.description}
                </Typography>
              </NumberBox>
            </Grid>
          ))}
        </Grid>
      </StyledContainer>
    </Box>
  );
}