"use client";

import { useEffect, useRef, useState } from "react";
import { Typography, Box, Container, useTheme, Tooltip } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";
import { Calendar, FileText, BarChart2 } from "lucide-react";
import { motion } from "framer-motion";
// Ensure GSAP plugins are registered
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

// Admin panel grid items data en español
const adminGridItems = [
  {
    id: "calendario",
    title: "Calendario",
    description: "Gestiona las horas médicas y bloques de horarios",
    emoji: "🗓",
  },
  {
    id: "especialistas",
    title: "Especialistas",
    description: "Gestiona los especialistas y sus perfiles",
    emoji: "👨‍⚕️",
  },
  {
    id: "pacientes",
    title: "Pacientes",
    description: "Administra los pacientes de la institución",
    emoji: "👥",
  },
  {
    id: "servicios",
    title: "Servicios",
    description: "Administra los servicios ofrecidos por los especialistas",
    emoji: "💉",
  },
  {
    id: "especialistas-institucion",
    title: "Especialistas de la institución",
    description: "Administra los especialistas de tu institución",
    emoji: "👨‍⚕️",
  },
  {
    id: "citas",
    title: "Citas",
    description: "Gestiona las citas y horarios",
    emoji: "⏰️",
  },
  {
    id: "conversaciones",
    title: "Conversaciones",
    description: "Monitorea las conversaciones activas",
    emoji: "💬",
  },

  {
    id: "metricas",
    title: "Métricas",
    description: "Visualiza estadísticas y métricas",
    emoji: "📈",
  },
  {
    id: "notificaciones",
    title: "Notificaciones",
    description: "Configura las notificaciones del sistema",
    emoji: "🔔",
  },
  {
    id: "llamadas",
    title: "Llamadas",
    description: "Monitorea y gestiona las llamadas",
    emoji: "☎️",
  },
  {
    id: "contenido-paginas",
    title: "Contenido de Páginas",
    description: "Personaliza el contenido de las páginas institucionales",
    emoji: "🎨",
  },
  {
    id: "base-conocimiento",
    title: "Base de Conocimiento",
    description: "Gestiona documentos para búsquedas inteligentes",
    emoji: "📚",
  },
];

export default function CinematicSectionAdmin() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [hoveredItem, setHoveredItem] = useState(null);

  const componentId = useRef(
    `admin-section-${Math.random().toString(36).substr(2, 9)}`
  );

  const sectionContainerRef = useRef(null);
  const titleRef = useRef(null);
  const iPadRef = useRef(null);
  const screenContentRef = useRef(null);
  const subtitleRef = useRef(null);
  const featureCardsRef = useRef(null);

  const { ref: sectionRef, inView } = useInView({
    triggerOnce: false,
    threshold: 0.25,
    rootMargin: "-5% 0%",
  });

  // Animation setup with improved cleanup
  useEffect(() => {
    // Store refs locally to avoid closure issues
    const sectionContainer = sectionContainerRef.current;
    const title = titleRef.current;
    const iPad = iPadRef.current;
    const screenContent = screenContentRef.current;
    const subtitle = subtitleRef.current;
    const featureCards = featureCardsRef.current;

    // Skip if elements aren't available or not in view
    if (!sectionContainer || !inView) return;

    // Create a context for this component's animations
    const ctx = gsap.context(() => {
      // Reset initial states
      gsap.set(title, { opacity: 0, y: -40 });
      gsap.set(iPad, { opacity: 0, y: 60 });
      gsap.set(screenContent, { opacity: 0 });
      gsap.set(subtitle, { opacity: 0, y: 30 });
      gsap.set(featureCards?.children || [], { opacity: 0, scale: 0.9 });

      // Create timeline with unique ID
      const tl = gsap.timeline({
        id: componentId.current,
        scrollTrigger: {
          id: `trigger-${componentId.current}`,
          trigger: sectionContainer,
          start: "top 70%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
        },
      });

      // Animation sequence
      tl.to(title, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out",
      })
        .to(
          iPad,
          {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power3.out",
          },
          "-=0.3"
        )
        .to(
          screenContent,
          {
            opacity: 1,
            duration: 0.4,
            ease: "power2.inOut",
          },
          "-=0.3"
        )
        .to(
          subtitle,
          {
            opacity: 1,
            y: 0,
            duration: 0.4,
            ease: "power3.out",
          },
          "-=0.2"
        )
        .to(
          featureCards?.children || [],
          {
            opacity: 1,
            scale: 1,
            stagger: 0.1,
            duration: 0.3,
            ease: "back.out(1.5)",
          },
          "-=0.2"
        );
    }, sectionContainer); // Scope to container

    // Cleanup function
    return () => {
      // Kill all animations and ScrollTriggers created in this context
      ctx.revert();

      // Extra safety: kill any ScrollTrigger with our ID
      const st = ScrollTrigger.getById(`trigger-${componentId.current}`);
      if (st) st.kill();
    };
  }, [inView]);

  return (
    <Box
      ref={(el) => {
        sectionRef(el);
        sectionContainerRef.current = el;
      }}
      sx={{
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: { xs: 10, md: 16 },
        overflow: "hidden",
        border: "0px !important",
        backgroundColor: "transparent !important",
        background: "transparent !important ",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <motion.h4
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 30 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="heading-primary"
            style={{
              fontWeight: 600,
              letterSpacing: "-0.02em",
              marginTop: 8,
              marginBottom: 20,
              fontSize: {
                xs: "1.75rem !important",
                sm: "2rem !important",
                md: "2.5rem !important",
              },
              background:
                "linear-gradient(90deg,rgb(59, 103, 122) 0%,rgb(43, 74, 85) 50%,rgb(40, 81, 98) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
              textAlign: "center",
            }}
          >
            Potencia la gestión de tu institución
          </motion.h4>
        </Box>

        {/* iPad showcase */}
        <Box
          ref={iPadRef}
          sx={{
            position: "relative",
            width: "100%",
            height: { xs: "500px", sm: "400px", md: "500px" },
            mb: 8,
            display: "flex",
            justifyContent: "center",
            opacity: 0,
          }}
        >
          {/* iPad device */}
          <Box
            sx={{
              position: "relative",
              width: { xs: "90%", sm: "80%", md: "70%" },
              maxWidth: "800px",
              height: "100%",
              perspective: "1500px",
            }}
          >
            {/* iPad body - simplified rectangle */}
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%) rotateX(10deg)",
                width: "85%",
                height: "90%",
                borderRadius: "24px",
                background: "linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 50%, #1a1a1a 100%)",
                padding: "16px",
                boxShadow: "0 20px 40px rgba(0, 0, 0, 0.4)",
                zIndex: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "24px",
                  background: "linear-gradient(135deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 50%)",
                  pointerEvents: "none",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "24px",
                  background: "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%)",
                  pointerEvents: "none",
                },
                "& .camera-notch": {
                  display: "none",
                  position: "absolute",
                  top: "16px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100px",
                  height: "20px",
                  background: "linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 50%, #1a1a1a 100%)",
                  borderRadius: "0 0 10px 10px",
                  zIndex: "10 !important",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "linear-gradient(135deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 50%)",
                    borderRadius: "0 0 10px 10px",
                    zIndex: "10 !important",
                    pointerEvents: "none",
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%)",
                    borderRadius: "0 0 10px 10px",
                    zIndex: "10 !important",
                    pointerEvents: "none",
                  }
                }
              }}
            >
              <Box className="camera-notch" />
              {/* Screen content */}
              <Box
                ref={screenContentRef}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "16px",
                  overflow: "hidden",
                  position: "relative",
                  backgroundColor: "#fff",
                  opacity: 0,
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid rgba(0,0,0,0.2)",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "linear-gradient(135deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 50%)",
                    pointerEvents: "none",
                    zIndex: 10,
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "linear-gradient(45deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 50%)",
                    pointerEvents: "none",
                    zIndex: 10,
                  }
                }}
              >
                {/* Navigation Bar */}
                <Box
                  sx={{
                    height: { xs: "30px", sm: "36px" },
                    width: "100%",
                    backgroundColor: "#f8f9fa",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: { xs: "0 12px", sm: "0 16px" },
                    position: "relative",
                    zIndex: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box
                      sx={{
                        width: { xs: "20px", sm: "24px" },
                        height: { xs: "20px", sm: "24px" },
                        borderRadius: "6px",
                        backgroundColor: "#e9ecef",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", sm: "14px" },
                          color: "#495057",
                          fontWeight: 600,
                        }}
                      >
                        V
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", sm: "14px" },
                        fontWeight: 600,
                        color: "#212529",
                      }}
                    >
                      Vita Admin
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box
                      sx={{
                        width: { xs: "20px", sm: "24px" },
                        height: { xs: "20px", sm: "24px" },
                        borderRadius: "50%",
                        backgroundColor: "#e9ecef",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "10px", sm: "12px" },
                          color: "#495057",
                        }}
                      >
                        MB
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Admin panel grid */}
                <Box
                  sx={{
                    flex: 1,
                    padding: { xs: "8px", sm: "12px" },
                    overflowY: "auto",
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "repeat(3, 1fr)",
                      sm: "repeat(3, 1fr)",
                      md: "repeat(4, 1fr)",
                    },
                    gap: { xs: "8px", sm: "12px" },
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {adminGridItems.map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        padding: { xs: "8px", sm: "12px" },
                        borderRadius: "12px",
                        boxShadow: "0 2px 4px rgba(0,0,0,.05)",
                        border: "0px solid #eaeaea",
                        backgroundColor:
                          hoveredItem === item.id ? "#f9f9f9" : "white",
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        transform:
                          hoveredItem === item.id
                            ? "translateY(-3px)"
                            : "translateY(0)",
                        boxShadow:
                          hoveredItem === item.id
                            ? "0 4px 12px rgba(0,0,0,0.08)"
                            : "0 2px 4px rgba(0,0,0,.05)",
                        "&:hover": {
                          transform: "translateY(-3px)",
                        },
                      }}
                      onMouseEnter={() => setHoveredItem(item.id)}
                      onMouseLeave={() => setHoveredItem(null)}
                    >
                      <Tooltip
                        title={
                          <Box sx={{ maxWidth: "160px", p: 0.5 }}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "12px",
                                mb: 0.25,
                                lineHeight: 1.2,
                              }}
                            >
                              {item.emoji} {item.title}
                            </Typography>
                            <Typography 
                              sx={{ 
                                fontSize: "10px",
                                lineHeight: 1.2,
                              }}
                            >
                              {item.description}
                            </Typography>
                          </Box>
                        }
                        placement="top"
                        arrow
                        enterDelay={0}
                        enterNextDelay={0}
                        sx={{
                          backdropFilter: "blur(5px)",
                          borderRadius: "6px",
                          "& .MuiTooltip-arrow": {
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              width: { xs: "24px", sm: "32px" },
                              height: { xs: "24px", sm: "32px" },
                              borderRadius: "6px",
                              backgroundColor: item.color,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: { xs: "4px", sm: "8px" },
                              color: "white",
                              fontSize: { xs: "14px", sm: "18px" },
                            }}
                          >
                            {item.emoji}
                          </Box>
                          <Typography
                            sx={{
                              fontSize: { xs: "11px", sm: "13px" },
                              fontWeight: 600,
                              color: "#333",
                              marginBottom: { xs: "2px", sm: "4px" },
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: "9px", sm: "11px" },
                              color: "#666",
                              lineHeight: 1.3,
                            }}
                          >
                            {item.description.length > 30
                              ? `${item.description.substring(0, 30)}...`
                              : item.description}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Decorative elements */}
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              right: "5%",
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              background: isDarkMode
                ? "radial-gradient(circle, rgba(78, 205, 196, 0.1) 0%, rgba(10, 25, 41, 0) 70%)"
                : "radial-gradient(circle, rgba(33, 147, 176, 0.1) 0%, rgba(248, 249, 250, 0) 70%)",
              filter: "blur(30px)",
              zIndex: 0,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              bottom: "15%",
              left: "10%",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              background: isDarkMode
                ? "radial-gradient(circle, rgba(138, 186, 211, 0.1) 0%, rgba(10, 25, 41, 0) 70%)"
                : "radial-gradient(circle, rgba(109, 213, 237, 0.1) 0%, rgba(248, 249, 250, 0) 70%)",
              filter: "blur(25px)",
              zIndex: 0,
            }}
          />
        </Box>

        {/* Content section */}
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            ref={subtitleRef}
            variant="h5"
            sx={{
              mb: 6,
              color: isDarkMode ? "text.primary" : "text.primary",
              fontWeight: 600,
              lineHeight: 1.4,
              fontSize: { xs: "1rem", sm: "1.125rem", md: "1.25rem" },
              maxWidth: "800px",
              mx: "auto",
              opacity: 0,
            }}
          >
            Liberamos tiempo para los especialistas y{" "}
            <Box
              component="span"
              sx={{
                fontWeight: 700,
                background: isDarkMode
                  ? "linear-gradient(135deg, #4ECDC4 0%, #8ABAD3 50%, #556270 100%)"
                  : "linear-gradient(135deg, #2193b0 0%, #6dd5ed 50%, #2193b0 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              potenciamos
            </Box>{" "}
            a los equipos administrativos.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
