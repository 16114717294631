"use client";

import { useEffect, useRef } from "react";
import { Typography, Box, Container, useTheme, Tooltip } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

// Ensure GSAP plugins are registered
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

export default function CinematicSection() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  // Create unique IDs for this component's animations
  const componentId = useRef(
    `patient-section-${Math.random().toString(36).substr(2, 9)}`
  );

  // Refs for animated elements
  const sectionContainerRef = useRef(null);
  const titleRef = useRef(null);
  const phoneDeviceRef = useRef(null);
  const screenContentRef = useRef(null);
  const subtitleRef = useRef(null);
  const highlightsRef = useRef(null);

  // Refs for message bubbles
  const bubbleBookRef = useRef(null);
  const bubbleServiceRef = useRef(null);
  const bubbleBookedRef = useRef(null);
  const bubbleCancelRef = useRef(null);
  const bubbleConfirmRef = useRef(null);
  const bubbleReviewRef = useRef(null);

  // Intersection observer with a unique key
  const { ref: sectionRef, inView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
    rootMargin: "-10% 0%",
  });

  const isInView = useInView(sectionRef, { once: false, amount: 0.2 });

  // Animation setup with improved cleanup
  useEffect(() => {
    // Store refs locally to avoid closure issues
    const sectionContainer = sectionContainerRef.current;
    const title = titleRef.current;
    const phoneDevice = phoneDeviceRef.current;
    const screenContent = screenContentRef.current;
    const subtitle = subtitleRef.current;
    const highlights = highlightsRef.current;
    const bubbleBook = bubbleBookRef.current;
    const bubbleService = bubbleServiceRef.current;
    const bubbleBooked = bubbleBookedRef.current;
    const bubbleCancel = bubbleCancelRef.current;
    const bubbleConfirm = bubbleConfirmRef.current;
    const bubbleReview = bubbleReviewRef.current;
    const bubbles = [
      bubbleBook,
      bubbleService,
      bubbleBooked,
      bubbleCancel,
      bubbleConfirm,
      bubbleReview,
    ].filter(Boolean);

    // Skip if elements aren't available or not in view
    if (!sectionContainer || !inView) return;

    // Create a context for this component's animations
    const ctx = gsap.context(() => {
      // Reset initial states
      gsap.set(title, { opacity: 0, y: -40 });
      gsap.set(phoneDevice, { opacity: 0, y: 60 });
      gsap.set(screenContent, { opacity: 0 });
      gsap.set(subtitle, { opacity: 0, y: 30 });
      gsap.set(highlights?.children || [], { opacity: 0, x: -20 });
      // Start smaller for a bigger pop
      gsap.set(bubbles, {
        opacity: 0,
        scale: 0.3,
        y: 25,
        transform: "rotate(0deg)",
      });

      // Create timeline with unique ID
      const tl = gsap.timeline({
        id: componentId.current,
        scrollTrigger: {
          id: `trigger-${componentId.current}`,
          trigger: sectionContainer,
          start: "top 70%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
        },
      });

      // Animation sequence
      tl.to(title, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power3.out",
      })
        .to(
          subtitle,
          {
            opacity: 1,
            y: 0,
            duration: 0.4,
            ease: "power3.out",
          },
          "-=0.2"
        )
        .to(
          highlights,
          {
            opacity: 1,
            duration: 0.3,
            ease: "power3.out",
          },
          "-=0.2"
        )
        .to(
          highlights?.children || [],
          {
            opacity: 1,
            x: 0,
            stagger: 0.09,
            duration: 0.2,
            ease: "power2.out",
          },
          "-=0.1"
        );

      // Only animate phone and bubbles if they exist (not on mobile)
      if (phoneDevice) {
        tl.to(
          phoneDevice,
          {
            opacity: 1,
            y: 0,
            duration: 0.6,
            ease: "power3.out",
          },
          "-=0.3"
        )
        .to(
          screenContent,
          {
            opacity: 1,
            duration: 0.4,
            ease: "power2.inOut",
          },
          "-=0.3"
        )
        // Animate bubbles popping out
        .to(
          bubbles,
          {
            opacity: 1,
            scale: (i) => 0.65 + i * 0.05,
            y: (i) => -5 + i * 2,
            stagger: 0.05,
            duration: 0.7,
            ease: "elastic.out(1, 0.5)",
          },
          "-=0.1"
        );
      }
    }, sectionContainer);

    return () => {
      ctx.revert();
      const st = ScrollTrigger.getById(`trigger-${componentId.current}`);
      if (st) st.kill();
    };
  }, [inView]);

  return (
    <Box
      ref={(el) => {
        sectionRef(el);
        sectionContainerRef.current = el;
      }}
      sx={{
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: { xs: 10, md: 16 },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <motion.h4
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 30 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="heading-primary"
            style={{
              fontWeight: 600,
              letterSpacing: "-0.02em",
              marginTop: 8,
              marginBottom: 80,
              fontSize: {
                xs: "1.75rem !important",
                sm: "2rem !important",
                md: "2.5rem !important",
              },
              background:
                "linear-gradient(90deg,rgb(59, 103, 122) 0%,rgb(43, 74, 85) 50%,rgb(40, 81, 98) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
              textAlign: "center",
            }}
          >
            Transforma la experiencia de los pacientes
          </motion.h4>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: 6, md: 8 },
          }}
        >
          {/* Phone device showcase */}
          <Box
            ref={phoneDeviceRef}
            sx={{
              position: "relative",
              width: { xs: "280px", sm: "320px" },
              height: { xs: "560px", sm: "640px" },
              flexShrink: 0,
              opacity: 0,
              mb: 3,
              transition: "transform 0.1s ease-out",
              display: { xs: "none", sm: "block" },
            }}
          >
            {/* Phone frame */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "40px",
                padding: "12px",
                background: isDarkMode
                  ? "linear-gradient(145deg, #2a2a2a, #1a1a1a)"
                  : "linear-gradient(145deg, #e6e6e6, #ffffff)",
                boxShadow: isDarkMode
                  ? "20px 20px 60px rgba(0, 0, 0, 0.5), -20px -20px 60px rgba(40, 40, 40, 0.3)"
                  : "20px 20px 60px rgba(0, 0, 0, 0.1), -20px -20px 60px rgba(255, 255, 255, 0.8)",
                border: isDarkMode
                  ? "1px solid rgba(255, 255, 255, 0.05)"
                  : "1px solid rgba(0, 0, 0, 0.05)",
                zIndex: 1,
              }}
            >
              {/* Phone notch */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "40%",
                  height: "25px",
                  borderRadius: "0 0 20px 20px",
                  backgroundColor: isDarkMode ? "#0A0A0A" : "#111",
                  zIndex: 3,
                }}
              />

              {/* Phone screen */}
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "32px",
                  overflow: "hidden",
                  position: "relative",
                  backgroundColor: "#000",
                  zIndex: 2,
                }}
              >
                {/* Screen content */}
                <Box
                  ref={screenContentRef}
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    opacity: 0,
                  }}
                >
                  <img
                    src="/phonebg.png"
                    alt="Vita mobile app interface"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    priority="true"
                  />
                </Box>
              </Box>
            </Box>

            {/* Message Bubbles Container */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 4,
                perspective: "1000px",
              }}
            >
              {[
                {
                  ref: bubbleBookRef,
                  src: "/messagebubbles/book.png",
                  alt: "Book appointment bubble",
                  tooltip: "Agendar una cita médica en segundos",
                  sx: {
                    top: "-7%",
                    left: "-65%",
                    width: "105%",
                    transform: "rotate(3deg)",
                    filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.2))",
                  },
                },
                {
                  ref: bubbleServiceRef,
                  src: "/messagebubbles/service.png",
                  alt: "Service bubble",
                  tooltip: "Consulta servicios disponibles",
                  sx: {
                    top: "25%",
                    left: "-75%",
                    width: "130%",
                    transform: "rotate(0deg)",
                    filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.2))",
                  },
                },
                {
                  ref: bubbleCancelRef,
                  src: "/messagebubbles/cancel.png",
                  alt: "Cancel appointment bubble",
                  tooltip: "Cancelar o reprogramar citas fácilmente",
                  sx: {
                    bottom: "-5%",
                    left: "-60%",
                    width: "90%",
                    transform: "rotate(6deg)",
                    filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.2))",
                  },
                },
                /* RIGHT BUBBLES */

                {
                  ref: bubbleBookedRef,
                  src: "/messagebubbles/booked.png",
                  alt: "Appointment booked bubble",
                  tooltip: "Confirmación instantánea de tu cita",
                  sx: {
                    top: "-5%",
                    right: "-60%",
                    width: "90%",
                    transform: "rotate(-3deg)",
                    filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.2))",
                  },
                },
                {
                  ref: bubbleConfirmRef,
                  src: "/messagebubbles/confirm.png",
                  alt: "Confirm appointment bubble",
                  tooltip: "Recordatorios automáticos de citas",
                  sx: {
                    bottom: "35%",
                    right: "-55%",
                    width: "95%",
                    transform: "rotate(0deg)",
                    filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.2))",
                  },
                },
                {
                  ref: bubbleReviewRef,
                  src: "/messagebubbles/review.png",
                  alt: "Review appointment bubble",
                  tooltip: "Comparte tu experiencia y valoración",
                  sx: {
                    bottom: "-10%",
                    right: "-45%",
                    width: "70%",
                    transform: "rotate(7deg)",
                    filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.2))",
                  },
                },
              ].map((bubble, index) => (
                <Box
                  key={index}
                  ref={bubble.ref}
                  sx={{
                    position: "absolute",
                    opacity: 0,
                    transformOrigin: "center center",
                    ...bubble.sx,
                  }}
                >
                  <Tooltip
                    title={bubble.tooltip}
                    placement={bubble.sx.left ? "left" : "right"}
                    arrow
                    enterDelay={0}
                    enterNextDelay={0}
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      backdropFilter: "blur(5px)",
                      borderRadius: "8px",
                      "& .MuiTooltip-arrow": {
                        color: "rgba(0, 0, 0, 0.8)",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={bubble.src}
                      alt={bubble.alt}
                      sx={{
                        display: "block",
                        width: "100%",
                        height: "auto",
                        maxWidth: "100%",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                        },
                      }}
                    />
                  </Tooltip>
                </Box>
              ))}
            </Box>

            {/* Decorative elements */}
            <Box
              sx={{
                position: "absolute",
                top: "-10%",
                right: "-15%",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                background: isDarkMode
                  ? "radial-gradient(circle, rgba(78, 205, 196, 0.15) 0%, rgba(10, 25, 41, 0) 70%)"
                  : "radial-gradient(circle, rgba(33, 147, 176, 0.15) 0%, rgba(248, 249, 250, 0) 70%)",
                filter: "blur(20px)",
                zIndex: 0,
              }}
            />

            <Box
              sx={{
                position: "absolute",
                bottom: "-5%",
                left: "-10%",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                background: isDarkMode
                  ? "radial-gradient(circle, rgba(138, 186, 211, 0.15) 0%, rgba(10, 25, 41, 0) 70%)"
                  : "radial-gradient(circle, rgba(109, 213, 237, 0.15) 0%, rgba(248, 249, 250, 0) 70%)",
                filter: "blur(20px)",
                zIndex: 0,
              }}
            />
          </Box>

          {/* Content section */}
          <Box
            sx={{
              maxWidth: "800px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Box
              ref={highlightsRef}
              sx={{
                mt: 2,
                opacity: 0,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                flexWrap: "wrap",
                justifyContent: "center",
                gap: { xs: 2, sm: 3, md: 4 },
                width: "100%",
              }}
            >
              {[
                "Respuestas rápidas y automáticas",
                "Cancelación y reprogramación de citas.",
                "Agendamiento simple y en segundos.",
                "Recordatorios, confirmaciones y encuestas.",
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { xs: "100%", sm: "45%", md: "45%" },
                    gap: 2,
                    mx: 1,
                    minWidth: { sm: "280px" },
                  }}
                >
                  <Box
                    sx={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: isDarkMode
                        ? "linear-gradient(135deg, #4ECDC4, #8ABAD3)"
                        : "linear-gradient(135deg, #2193b0, #6dd5ed)",
                      flexShrink: 0,
                    }}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12L10 17L20 7"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: "text.secondary",
                      flex: 1,
                      textAlign: "left"
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
