import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "@emotion/react";
import AppAppBar from "./AppAppBar";
import Footer from "./Footer";
import BlobAnimation from "./BlobAnimation";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import TemplateFrame from "../TemplateFrame";
import getMPTheme from "../theme/getMPTheme";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  backdropFilter: "blur(20px)",
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  borderRadius: "16px",
  overflow: "hidden",
  border: "1px solid",
  borderColor: theme.palette.divider,
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: theme.shadows[10],
  },
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingTop: "56.25%", // 16:9 aspect ratio
  width: "100%",
  overflow: "hidden",
}));

const VideoIframe = styled("iframe")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  border: "none",
}));

// Sample video data - this will be replaced with actual videos
let demoVideos = [
  {
    id: 5,
    title: "Gestión completa de citas",
    description:
      "Nunca más dependarás de procesos manuales para manejar tus citas.",
    videoId: "9b5l4h9ysFk", // This is a placeholder YouTube ID
  },

  {
    id: 1,
    title: "Orientación de Pacientes",
    description:
      "Un vistazo a cómo funciona la orientación de la asistenta virtual de Vita.",
    videoId: "Y9XHITMl_OU", // This is a placeholder YouTube ID
  },

  {
    id: 6,
    title: "Agendamiento Web",
    description:
      "La solución elegante que destaca entre sistemas de agendamiento anticuados.",
    videoId: "UrmgWeYTCiw",
  },

  {
    id: 3,
    title: "Agenda y cancela en segundos",
    description:
      "Mira como la asistente virtual de Vita agenda y cancela en segundos a tus pacientes.",
    videoId: "h-Ai9DMAMNk",
  },
  {
    id: 2,
    title: "Página de agenda",
    description:
      "Mira como creamos una página de agenda para una clínica de kinesiología.",
    videoId: "p4WUj23jYKE",
  },

  {
    id: 4,
    title: "Quick Start",
    description:
      "Una demostración rápida de cómo puedes empezar a usar Vita en tu clínica en ~2 minutos.",
    videoId: "qYjSirhogkc", // This is a placeholder YouTube ID
  },
  {
    id: 0,
    title: "Responde dudas repetitivas de pacientes",
    description:
      "La asistente virtual de Vita puede responder preguntas comunes de pacientes de forma rápida y eficiente.",
    videoId: "iZEiORwkwpQ", // This is a placeholder YouTube ID
  },
];

/* scramble them */
demoVideos = demoVideos.sort(() => Math.random() - 0.5);
export default function Demos() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const theme = useTheme();

  React.useEffect(() => {
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
    >
      <ThemeProvider
        theme={showCustomTheme ? MPTheme : createTheme({ palette: { mode } })}
      >
        <CssBaseline enableColorScheme />
        <div
          className="responsive-container"
          style={{
            width: "100%",
            overflowX: "hidden",
            maxWidth: "100%",
            position: "relative",
          }}
        >
          <AppAppBar />
          <Box
            sx={{
              position: "relative",
              pt: { xs: 16, sm: 20, md: 25 },
              pb: { xs: 8, sm: 10, md: 12 },
              minHeight: "100vh",
            }}
          >
            <BlobAnimation />
            <Container maxWidth="lg">
              <Typography
                component="h1"
                variant="h2"
                sx={{
                  fontWeight: 600,
                  letterSpacing: "-0.02em",
                  marginBottom: 4,
                  fontSize: { xs: "1.75rem", sm: "2rem", md: "2.5rem" },
                  background:
                    "linear-gradient(90deg,rgb(70, 132, 159) 0%,rgb(65, 110, 127) 50%,rgb(59, 98, 115) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  textAlign: "center",
                }}
              >
                Demos
              </Typography>
              <Typography
                variant="h5"
                component="p"
                sx={{
                  mb: 6,
                  textAlign: "center",
                  maxWidth: "800px",
                  mx: "auto",
                  color: "text.secondary",
                }}
              >
                Echa un vistazo rápido a Vita en acción.
              </Typography>

              <Grid container spacing={4}>
                {demoVideos.map((video) => (
                  <Grid item xs={12} sm={6} md={4} key={video.id}>
                    <StyledCard>
                      <VideoContainer>
                        <VideoIframe
                          src={`https://www.youtube.com/embed/${video.videoId}`}
                          title={video.title}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </VideoContainer>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {video.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {video.description}
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
          <Footer />
        </div>
      </ThemeProvider>
    </TemplateFrame>
  );
}
