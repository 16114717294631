import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MarketingPage from "./template/MarketingPage";
import Documentation from "./template/components/Documentation";
import Demos from "./template/components/Demos";
import WhatsAppButton from "./template/components/WhatsAppButton";
import { happyLog } from "./template/components/coolLog";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    happyLog();
  }, []);
  return (
    <Router>
      <WhatsAppButton phoneNumber="56967112269" message="/ayuda" />
      <Routes>
        <Route path="/documentation/*" element={<Documentation />} />
        <Route path="/demos" element={<Demos />} />
        <Route path="/*" element={<MarketingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
