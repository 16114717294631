import * as React from "react";
import { styled, alpha, keyframes } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Sitemark from "./VitaLogo";
import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  [theme.breakpoints.up("md")]: {
    padding: "8px 12px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "10px 10px",
  },
}));

// Define a rotation animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Create a styled component for the "New" starburst
const NewStarburst = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "-1px",
  right: "-1px",
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "14px",
  height: "14px",
  fontSize: "5px",
  fontWeight: "bold",
  color: "#fff",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#FF4081",
    borderRadius: "50%",
    animation: `${spin} 8s linear infinite`,
    zIndex: -1,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    backgroundColor: "#FF4081",
    width: "100%",
    height: "100%",
    clipPath:
      "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
    animation: `${spin} 8s linear infinite`,
    zIndex: -1,
  },
}));

export default function AppAppBar() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavigation = (section) => {
    if (location.pathname !== "/") {
      // If we're not on the home page, navigate to home and then scroll
      navigate("/");
      // Use setTimeout to wait for navigation to complete before scrolling
      setTimeout(() => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      // If we're already on the home page, just scroll
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    setOpen(false); // Close drawer after clicking
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: { xs: 2, sm: 4, md: 10 },
      }}
      id="appbar"
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <RouterLink
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Sitemark />
            </RouterLink>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => handleNavigation("features")}
              >
                Producto
              </Button>
              <Box sx={{ position: "relative" }}>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  component={RouterLink}
                  to="/demos"
                >
                  Demos
                </Button>
                <NewStarburst>NEW</NewStarburst>
              </Box>
              <Button
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
                onClick={() => handleNavigation("faq")}
              >
                FAQ
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
                onClick={() => handleNavigation("contact")}
              >
                Contacto
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Tooltip title="Disponible ya!" placement="bottom" arrow>
              <Button
                className="try-vita-btn"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `${theme.palette.divider} 1px solid`,
                }}
                href="https://wa.me/56967112269"
              >
                Pruébalo&nbsp;
                <WhatsAppIcon
                  sx={{
                    fontSize: "1.2rem",
                  }}
                />
              </Button>
            </Tooltip>

            <Button
              className="try-vita-btn"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `${theme.palette.divider} 1px solid`,
              }}
              href="https://app.vita.lat"
            >
              Iniciar sesión
              <img
                src="/vitaicon.png"
                alt="Vita Logo"
                className="vita-logo-btn"
              />
            </Button>
          </Box>
          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton
              aria-label="Menu button"
              onClick={toggleDrawer(true)}
              sx={{ padding: { xs: "1px", md: "8px" } }}
            >
              <MenuIcon sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }} />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => handleNavigation("features")}>
                  Producto
                </MenuItem>
                <Box sx={{ position: "relative" }}>
                  <MenuItem
                    component={RouterLink}
                    to="/demos"
                    onClick={toggleDrawer(false)}
                  >
                    Demos
                  </MenuItem>
                  <NewStarburst
                    sx={{
                      top: "50%",
                      right: "-5px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    NEW
                  </NewStarburst>
                </Box>
                <MenuItem onClick={() => handleNavigation("faq")}>FAQ</MenuItem>
                <MenuItem onClick={() => handleNavigation("contact")}>
                  Contacto
                </MenuItem>
                <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    href="https://app.vita.lat"
                  >
                    Prueba Vita
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
