"use client";

import { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence, useInView } from "framer-motion";
import {
  SmileIcon as Tooth,
  Brain,
  Hospital,
  Activity,
  Apple,
  Footprints,
  Stethoscope,
  Sparkles,
  Heart,
  Cat,
  MessageCircleQuestionIcon,
} from "lucide-react";

const styles = {
  section: {
    position: "relative",
    padding: "3rem 0",
    overflow: "hidden",
    background: "transparent",
  },
  container: {
    position: "relative",
    maxWidth: "1000px",
    margin: "0 auto",
    padding: "0 1rem",
    zIndex: 10,
  },
  header: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  subtitle: {
    display: "inline-block",
    fontSize: "0.875rem",
    fontWeight: 500,
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    marginBottom: "0.5rem",
    color: "#6b7280",
  },

  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: ".5rem",
    marginBottom: "2rem",
    justifyItems: "center",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  iconContainer: {
    position: "relative",
    width: "75%",
    aspectRatio: "1/1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: "0.5rem",
  },
  iconText: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  descriptionContainer: {
    position: "relative",
    height: "120px",
    marginBottom: "2rem",
  },
  descriptionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  activeDescriptionBox: {
    maxWidth: "32rem",
    margin: "0 auto",
    padding: "1.5rem",
    borderRadius: "0.5rem",
  },
  descriptionTitle: {
    fontSize: "1.25rem",
    fontWeight: 700,
    marginBottom: "0.5rem",
  },
  descriptionText: {
    color: "#ffffff60",
    fontSize: "0.875rem",
    textAlign: "justify",
  },
  defaultDescription: {
    color: "#6b7280",
    fontStyle: "italic",
    maxWidth: "32rem",
    margin: "0 auto",
    fontSize: "0.875rem",
  },
  bottomDescription: {
    maxWidth: "48rem",
    margin: "0 auto",
    textAlign: "center",
  },
  bottomText: {
    color: "#4b5563",
    fontSize: "0.875rem",
  },
  ctaContainer: {
    marginTop: "2rem",
    textAlign: "center",
  },
  ctaButton: {
    padding: "0.75rem 1.5rem",
    borderRadius: "9999px",
    color: "white",
    fontWeight: 500,
    background:
      "linear-gradient(135deg, var(--primary-color) 0%, var(--fourth-color) 100%)",
    border: "none",
    cursor: "pointer",
  },
};

// Media queries
const mediaQueries = {
  md: "@media (min-width: 768px)",
  lg: "@media (min-width: 1024px)",
};

const InteractiveUseCasesSection = () => {
  const [activeCase, setActiveCase] = useState(null);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.2 });
  const [isMobile, setIsMobile] = useState(false);

  // Check if on mobile device
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    checkMobile();

    // Listen for resize events
    window.addEventListener("resize", checkMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Function to toggle the active case - disabled on mobile
  const toggleActive = (id) => {
    if (!isMobile) {
      setActiveCase(activeCase === id ? null : id);
    }
  };

  // Use cases with custom colors and gradients from the provided palette
  const useCases = [
    {
      id: 10,
      name: "Hospitales y clínicas",
      color: "var(--primary-color)",
      gradient: "var(--gradient-primary)",
      icon: Hospital,
      description:
        "Solución integral para la administración de centros médicos multidisciplinarios con múltiples especialidades. Fidelizamos y mejoramos la experiencia de tus pacientes.",
    },
    {
      id: 1,
      name: "Odontología",
      color: "var(--primary-color)",
      gradient: "var(--gradient-primary)",
      icon: Tooth,
      description:
        "Gestión completa de pacientes dentales, historial clínico y seguimiento de tratamientos odontológicos personalizados. Responde dudas de presupuestos, programas de tratamiento y más.",
    },
    {
      id: 2,
      name: "Psicología",
      color: "var(--secondary-color)",
      gradient: "var(--gradient-secondary)",
      icon: Brain,
      description:
        "Guía a tu paciente al cuidado de su salud mental. Modificamos nuestro asistente orientador para ayudar a tu paciente a descubrir su profesional ideal.",
    },
    {
      id: 4,
      name: "Kinesiología",
      color: "var(--secondary-color)",
      gradient: "var(--gradient-secondary)",
      icon: Activity,
      description:
        "Automatizamos el seguimiento de rehabilitación física y respondemos a las dudas frecuentes de los pacientes, con los conocimientos tuyos y de tu equipo. ",
    },
    {
      id: 5,
      name: "Nutrición",
      color: "var(--primary-color)",
      gradient: "var(--gradient-primary)",
      icon: Apple,
      description:
        "Guía a tus pacientes al nutricionista y plan de tratamiento ideal. Vita puede responder dudas de dietas, hacer seguimiento de mediciones y más.",
    },

    {
      id: 7,
      name: "Consultas independientes",
      color: "var(--primary-color)",
      gradient: "var(--gradient-primary)",
      icon: Stethoscope,
      description:
        "Aliviamos tu carga administrativa. Te ayudamos a gestionar tu agenda de consultas. Preocúpate de tus pacientes y de tu equipo, y nos encargamos de todo lo demás.",
    },
    {
      id: 8,
      name: "Clínicas estéticas",
      color: "var(--secondary-color)",
      gradient: "var(--gradient-secondary)",
      icon: Sparkles,
      description:
        "Ayuda a tus pacientes a encontrar su tratamiento estético ideal con los resultados que esperan. Vita puede interpretar fotos, responder dudas de tratamientos, y hacer los seguimientos necesarios",
    },

    {
      id: 9,
      name: "Más",
      color: "var(--primary-color)",
      gradient: "var(--gradient-primary)",
      icon: MessageCircleQuestionIcon,
      description:
        "Si tu centro o equipo no está en la lista, contáctanos y nos adaptaremos a tus necesidades, sin compromiso durante un mes.",
    },
  ];

  // Generate blob path (simplified)
  const generateBlobPath = (seed) => {
    const randomness = seed % 5;

    // Base paths for different blob shapes - more circular with slight variations
    const blobPaths = [
      "M35,-35C45.9,-25,55.9,-12.5,56.4,0.5C56.9,13.6,47.8,27.1,37,36.1C26.1,45.2,13.1,49.6,0,49.6C-13,49.6,-26.1,45.2,-36.1,36.1C-46.2,27.1,-53.3,13.6,-53.8,0C-54.3,-13.5,-48.3,-27.1,-38.3,-37.1C-28.3,-47.1,-14.1,-53.5,-0.4,-53.1C13.4,-52.8,26.8,-45.7,35,-35Z",
      "M39,-39C50.7,-29,60.4,-14.5,60.2,0C60.1,14.6,50.1,29.1,38.1,37.1C26.1,45.2,13.1,46.6,0,46.6C-13,46.6,-26.1,45.2,-36.6,37.1C-47.1,29.1,-55.1,14.6,-55.2,0C-55.3,-14.5,-47.5,-29,-37.5,-39C-27.5,-49,-13.8,-54.5,0,-54.5C13.7,-54.5,27.4,-49,39,-39Z",
      "M41,-41C52.8,-31,62.5,-15.5,62.5,0C62.5,15.5,52.8,31,41,41.5C29.1,52,14.6,57.5,0,57.5C-14.5,57.5,-29.1,52,-39.5,41.5C-50,31,-56.2,15.5,-56.2,0C-56.2,-15.5,-50,-31,-39.5,-41C-29.1,-51,-14.5,-55.5,0,-55.5C14.6,-55.5,29.1,-51,41,-41Z",
      "M37,-37C48.2,-27,57.9,-13.5,57.9,0C57.9,13.5,48.2,27,37,36C25.7,45,12.9,49.5,0,49.5C-12.8,49.5,-25.7,45,-35.7,36C-45.7,27,-52.8,13.5,-52.8,0C-52.8,-13.5,-45.7,-27,-35.7,-37C-25.7,-47,-12.8,-53.5,0,-53.5C12.9,-53.5,25.7,-47,37,-37Z",
      "M35,-35C45.5,-25,55,-12.5,55,0C55,12.5,45.5,25,35,33.5C24.5,42,12.2,46.5,0,46.5C-12.2,46.5,-24.5,42,-33.5,33.5C-42.5,25,-48.5,12.5,-48.5,0C-48.5,-12.5,-42.5,-25,-33.5,-35C-24.5,-45,-12.2,-52.5,0,-52.5C12.2,-52.5,24.5,-45,35,-35Z",
    ];

    return blobPaths[randomness];
  };

  return (
    <section ref={sectionRef} style={styles.section}>
      {/* Background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute w-[800px] h-[800px] rounded-full blur-[120px] opacity-10"
          style={{
            background: `radial-gradient(circle, var(--secondary-color) 0%, var(--primary-color) 100%)`,
            top: "10%",
            right: "-20%",
          }}
        />
        <div
          className="absolute w-[600px] h-[600px] rounded-full blur-[100px] opacity-5"
          style={{
            background: `radial-gradient(circle, var(--fourth-color) 0%, var(--tertiary-color) 100%)`,
            bottom: "5%",
            left: "-10%",
          }}
        />
      </div>

      {/* Main content container */}
      <div style={styles.container}>
        {/* Header */}
        <motion.div
          style={styles.header}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 50 }}
          transition={{ duration: 0.8 }}
        >
          <motion.span
            style={styles.subtitle}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            Solución integral
          </motion.span>

          <motion.h4
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 30 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="heading-primary"
            style={{
              fontWeight: 600,
              letterSpacing: "-0.02em",
              marginTop: 8,
              marginBottom: 4,
              fontSize: { xs: "1.75rem !important", sm: "2rem !important", md: "2.5rem !important" },
              background:
                "linear-gradient(90deg,rgb(59, 103, 122) 0%,rgb(43, 74, 85) 50%,rgb(40, 81, 98) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
              textAlign: "center",
            }}
          >
            Vita, para:
          </motion.h4>

          <motion.div
            style={{
              ...styles.divider,
              width: isInView ? "96px" : "0px",
            }}
            initial={{ width: 0 }}
            animate={{ width: isInView ? "96px" : 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          />
        </motion.div>

        {/* Interactive Blob Grid - 4 rows of 2 in mobile, 2 rows of 4 in desktop */}
        <div
          style={{
            ...styles.grid,
            gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
            gap: isMobile ? "1rem" : ".5rem",
          }}
        >
          {useCases.map((useCase, index) => (
            <motion.div
              key={useCase.id}
              style={{
                ...styles.card,
                marginBottom: isMobile ? "0.5rem" : 0,
              }}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 30 }}
              transition={{ duration: 0.5, delay: 0.1 * index }}
            >
              <motion.div
                style={{
                  ...styles.iconContainer,
                  width: isMobile ? "40%" : "75%",
                  cursor: isMobile ? "default" : "pointer",
                }}
                whileHover={{
                  scale: isMobile ? 1 : 1.05,
                }}
                whileTap={{ scale: isMobile ? 1 : 0.95 }}
                onClick={() => toggleActive(useCase.id)}
                animate={{
                  x:
                    activeCase === useCase.id
                      ? 0
                      : isMobile
                      ? 0
                      : [2, -2, 0, 2, -1, 0],
                  y:
                    activeCase === useCase.id
                      ? 0
                      : isMobile
                      ? 0
                      : [1, -1, 2, -1, 0, 1],
                }}
                transition={{
                  x: {
                    repeat: isMobile ? 0 : Infinity,
                    repeatType: "mirror",
                    duration: 7 + (index % 3),
                    ease: "easeInOut",
                  },
                  y: {
                    repeat: isMobile ? 0 : Infinity,
                    repeatType: "mirror",
                    duration: 3 + (index % 2),
                    ease: "easeInOut",
                  },
                }}
              >
                {/* SVG Blob with gradient */}
                <svg
                  viewBox="0 0 200 200"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                  animate={{
                    x:
                      activeCase === useCase.id
                        ? 0
                        : isMobile
                        ? 0
                        : [2, -2, 0, 2, -1, 0],
                    y:
                      activeCase === useCase.id
                        ? 0
                        : isMobile
                        ? 0
                        : [1, -1, 2, -1, 0, 1],
                  }}
                  transition={{
                    x: {
                      repeat: isMobile ? 0 : Infinity,
                      repeatType: "mirror",
                      duration: 4 + (index % 3),
                      ease: "easeInOut",
                    },
                    y: {
                      repeat: isMobile ? 0 : Infinity,
                      repeatType: "mirror",
                      duration: 5 + (index % 2),
                      ease: "easeInOut",
                    },
                  }}
                >
                  <defs>
                    <linearGradient
                      id={`gradient-${useCase.id}`}
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop offset="0%" stopColor={useCase.color} />
                      <stop
                        offset="100%"
                        stopColor={
                          useCase.id % 2 === 0
                            ? "var(--fourth-color)"
                            : "var(--secondary-color)"
                        }
                      />
                    </linearGradient>
                  </defs>

                  {/* Background fill path with smooth transition */}
                  <motion.path
                    d={generateBlobPath(useCase.id)}
                    transform="translate(100 100) scale(1.3)"
                    fill={`url(#gradient-${useCase.id})`}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: activeCase === useCase.id ? 1 : 0,
                    }}
                    transition={{
                      duration: 0.6,
                      ease: "easeInOut",
                    }}
                  />

                  {/* Stroke path */}
                  <motion.path
                    d={generateBlobPath(useCase.id)}
                    initial={{
                      pathLength: 0,
                    }}
                    animate={{
                      pathLength: 1,
                    }}
                    transition={{
                      duration: 1,
                    }}
                    transform="translate(100 100) scale(1.3)"
                    style={{
                      fill: "transparent",
                      stroke: useCase.color,
                      strokeWidth: 5,
                    }}
                  />
                </svg>

                {/* Icon */}
                <motion.div
                  style={{
                    position: "relative",
                    zIndex: 10,
                  }}
                  animate={{
                    scale: activeCase === useCase.id ? (isMobile ? 1 : 1.2) : 1,
                    x:
                      activeCase === useCase.id
                        ? 0
                        : isMobile
                        ? 0
                        : [2, -2, 0, 2, -1, 0],
                    y:
                      activeCase === useCase.id
                        ? 0
                        : isMobile
                        ? 0
                        : [1, -1, 2, -1, 0, 1],
                  }}
                  transition={{
                    scale: { duration: 0.5 },
                    x: {
                      repeat: isMobile ? 0 : Infinity,
                      repeatType: "mirror",
                      duration: 4 + (index % 3),
                      ease: "easeInOut",
                    },
                    y: {
                      repeat: isMobile ? 0 : Infinity,
                      repeatType: "mirror",
                      duration: 5 + (index % 2),
                      ease: "easeInOut",
                    },
                  }}
                >
                  <useCase.icon
                    size={isMobile ? 24 : 48}
                    color="white"
                    strokeWidth={1.5}
                    style={{
                      filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.2))",
                      opacity: activeCase === useCase.id ? 1 : 0.6,
                      transition: "opacity 0.6s ease-in-out",
                    }}
                  />
                </motion.div>
              </motion.div>

              <motion.p
                style={{
                  ...styles.iconText,
                  color: activeCase === useCase.id ? useCase.color : "#374151",
                  transition: "color 0.6s ease-in-out",
                  fontSize: isMobile ? "0.7rem" : "0.875rem",
                  marginTop: isMobile ? 0 : "0.5rem",
                }}
                animate={{
                  scale: activeCase === useCase.id ? (isMobile ? 1 : 1.05) : 1,
                }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
              >
                {useCase.name}
              </motion.p>
            </motion.div>
          ))}
        </div>

        {/* Description Area - Hidden on mobile */}
        {!isMobile && (
          <div style={styles.descriptionContainer}>
            <AnimatePresence mode="wait">
              {activeCase ? (
                <motion.div
                  key={`description-${activeCase}`}
                  style={styles.descriptionBox}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{
                    duration: 0.4,
                    ease: "easeOut",
                  }}
                >
                  <motion.div
                    style={{
                      ...styles.activeDescriptionBox,
                      background: `linear-gradient(135deg, ${
                        useCases.find((c) => c.id === activeCase)?.color
                      }10 0%, ${
                        useCases.find((c) => c.id === activeCase)?.color
                      }20 100%)`,
                      border: `1px solid ${
                        useCases.find((c) => c.id === activeCase)?.color
                      }30`,
                    }}
                    transition={{ duration: 0.4, ease: "easeOut" }}
                  >
                    <h3
                      style={{
                        ...styles.descriptionTitle,
                        color: useCases.find((c) => c.id === activeCase)?.color,
                        transition: "color 0.4s ease-out",
                      }}
                    >
                      {useCases.find((c) => c.id === activeCase)?.name}
                    </h3>
                    <p style={styles.descriptionText}>
                      {useCases.find((c) => c.id === activeCase)?.description}
                    </p>
                  </motion.div>
                </motion.div>
              ) : (
                <motion.div
                  key="default-description"
                  style={styles.descriptionBox}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.4, ease: "easeOut" }}
                >
                  <p style={styles.defaultDescription}>
                    Selecciona una especialidad para ver más detalles sobre cómo
                    Vita puede ayudarte.
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>

      {/* Decorative elements */}
      <div
        className="absolute bottom-0 left-0 w-full h-px opacity-10"
        style={{
          background: `linear-gradient(90deg, transparent 0%, var(--primary-color) 50%, transparent 100%)`,
        }}
      />
    </section>
  );
};

export default InteractiveUseCasesSection;
