import React, { useEffect, useRef } from "react";
import { Typography, useTheme } from "@mui/material";
import "./Integrations.css";
import { motion, useInView } from "framer-motion";

const Integrations = () => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const isInView = useInView(textRef, { once: true, amount: 0.2 });

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.setProperty(
        "--background-color",
        theme.palette.background.default
      );
    }
  }, [theme.palette.background.default]);

  const partners = [
    /* { id: 2, name: "UDD Ventures", logo: "/logos/udd.svg" }, */
    {
      id: 3,
      name: "Medilink",
      logo: "/logos/medilink.svg",
      type: "integración",
    },
    { id: 4, name: "Reservo", logo: "/logos/reservo.png", type: "integración" },
    {
      id: 5,
      name: "Dentalink",
      logo: "/logos/dentalink.svg",
      type: "integración",
    },
    { id: 8, name: "Masterkey", logo: "/logos/mk.png", type: "integración" },
    {
      id: 7,
      name: "agendapro",
      logo: "/logos/agendapro.svg",
      type: "integración",
    },
    { id: 6, name: "iTera", logo: "/logos/itera.png", type: "cliente" },
    { id: 9, name: "Team Kine", logo: "/logos/teamkine.png", type: "cliente" },
    {
      id: 10,
      name: "Growth 4 Good",
      logo: "/logos/g4g.png",
      type: "cliente",
    },
    {
      id: 11,
      name: "Preb",
      logo: "/logos/preb.webp",
      type: "cliente",
    },
    {
      id: 12,
      name: "MercadoPago",
      logo: "/logos/mp.png",
      type: "integración",
    },

    {
      id: 13,
      name: "Google Calendar",
      logo: "/logos/gc.png",
      type: "integración",
    },
  ];

  const doubledPartners = [...partners, ...partners];

  return (
    <section className="integrations-section">
      <div className="container">
        <div className="carousel-wrapper" ref={containerRef}>
          <div className="logos-scroll">
            <div className="logos-track">
              {doubledPartners.map((partner, index) => (
                <div
                  key={`${partner.id}-${index}`}
                  className={`partner-logo-container ${partner.type}`}
                >
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="partner-logo"
                  />
                  <span className="partner-type">{partner.type}</span>
                </div>
              ))}
            </div>
            <div className="logos-track" aria-hidden="true">
              {doubledPartners.map((partner, index) => (
                <div
                  key={`${partner.id}-${index}-clone`}
                  className={`partner-logo-container ${partner.type}`}
                >
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="partner-logo"
                  />
                  <span className="partner-type">{partner.type}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="text-container my-5" ref={textRef}>
        <motion.span
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          style={{
            textTransform: "uppercase",
            letterSpacing: "0.2em",
            fontSize: "0.7rem",
            fontWeight: 500,
            marginTop: 6,
            marginBottom: 1.5,
            color: theme.palette.text.secondary,
            opacity: 0.85,
          }}
        >
          Integraciones y más
        </motion.span>
        <motion.h4
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 30 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="heading-primary"
          style={{
            fontWeight: 600,
            letterSpacing: "-0.02em",
            marginTop: 15,
            marginBottom: 30,
            fontSize: { xs: "1.75rem", sm: "2rem", md: "2.5rem" },
            background:
              "linear-gradient(90deg,rgb(59, 103, 122) 0%,rgb(43, 74, 85) 50%,rgb(40, 81, 98) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            textAlign: "center",
          }}
        >
          Vita Conecta y Crece
        </motion.h4>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          style={{
            color: theme.palette.text.secondary,
            maxWidth: "580px",
            margin: "0 auto 9rem",
            fontSize: { xs: "0.95rem", md: "1.05rem" },
            fontWeight: 400,
            lineHeight: 1.6,
            letterSpacing: "0.015em",
            opacity: 0.9,
            textAlign: "center",
          }}
        >
          Vita se integra automáticamente con las herramientas que ya utilizas.
          Sin desarrollos adicionales ni instalaciones complejas.
          <br /> <br />Y si aún no cuentas con ellas, las tenemos para ti.
        </motion.p>
      </div>
    </section>
  );
};

export default Integrations;
